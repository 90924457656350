var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"bg_col_fff bor_rad_8 box_sha_0_0_8_8_black_80_per p_16 m_0_16 m_t_16"},[_vm._v(" 数据截止："+_vm._s(_vm.statisticInfo.refDate ? _vm.moment(_vm.statisticInfo.refDate).format('YYYY-MM-DD') : '-')+" ")]),_vm._l(([
      {
        label0: '当前累计关注数量',
        value0: _vm.columnAmount(_vm.statisticInfo.cumulateUser, {
          fixed: 0,
        }),

        column0: {
          label: '昨日新增',
          value: _vm.columnAmount(_vm.statisticInfo.newUser, {
            fixed: 0,
          }),
        },
        column1: {
          label: '昨日减少',
          value: _vm.columnAmount(_vm.statisticInfo.cancelUser, {
            fixed: 0,
          }),
        },
        column2: {
          label: '昨日净增',
          value: _vm.columnAmount(_vm.statisticInfo.increasedUser, {
            fixed: 0,
          }),
        },
      },

      //
    ]),function(item,index){return _c('div',{key:index,staticClass:"bg_col_fff bor_rad_8 box_sha_0_0_8_8_black_80_per p_16 m_0_16 m_t_16"},[_c('div',{staticClass:"dis_flex"},[_c('div',{staticClass:"flex_1"},[_c('div',{staticClass:"font_16 font_bold"},[_vm._v(_vm._s(item.label0))]),_c('div',{staticClass:"font_24 font_bold m_t_8"},[_vm._v(_vm._s(item.value0))])]),(item.label1)?_c('div',{staticClass:"m_0_16 m_t_4"},[_c('div',{staticClass:"font_14 font_bold"},[_vm._v(_vm._s(item.label1))]),_c('div',{staticClass:"font_18 font_bold m_t_8"},[_vm._v(_vm._s(item.value1))])]):_vm._e(),(item.label2)?_c('div',{staticClass:"m_0_16 m_t_4"},[_c('div',{staticClass:"font_14 font_bold"},[_vm._v(_vm._s(item.label2))]),_c('div',{staticClass:"font_18 font_bold m_t_8"},[_vm._v(_vm._s(item.value2))])]):_vm._e()]),_c('div',{staticClass:"dis_flex m_t_8"},[_c('div',{staticClass:"flex_1 dis_flex font_12"},[_c('div',[_vm._v(_vm._s(item.column0.label))]),_c('div',{staticClass:"col_66BB6A font_bold m_l_8"},[_vm._v(_vm._s(item.column0.value))])]),_c('div',{staticClass:"flex_1 dis_flex font_12"},[_c('div',[_vm._v(_vm._s(item.column1.label))]),_c('div',{staticClass:"col_66BB6A font_bold m_l_8"},[_vm._v(_vm._s(item.column1.value))])]),_c('div',{staticClass:"flex_1 dis_flex font_12"},[_c('div',[_vm._v(_vm._s(item.column2.label))]),_c('div',{staticClass:"col_2396F5 font_bold m_l_8"},[_vm._v(_vm._s(item.column2.value))])])])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }