<template>
  <div>
    <div
      class="
        bg_col_fff
        bor_rad_8
        box_sha_0_0_8_8_black_80_per
        p_16
        m_0_16 m_t_16
      "
    >
      数据截止：{{
        statisticInfo.refDate
          ? moment(statisticInfo.refDate).format('YYYY-MM-DD')
          : '-'
      }}
    </div>

    <div
      v-for="(item, index) in [
        {
          label0: '当前累计关注数量',
          value0: columnAmount(statisticInfo.cumulateUser, {
            fixed: 0,
          }),

          column0: {
            label: '昨日新增',
            value: columnAmount(statisticInfo.newUser, {
              fixed: 0,
            }),
          },
          column1: {
            label: '昨日减少',
            value: columnAmount(statisticInfo.cancelUser, {
              fixed: 0,
            }),
          },
          column2: {
            label: '昨日净增',
            value: columnAmount(statisticInfo.increasedUser, {
              fixed: 0,
            }),
          },
        },

        //
      ]"
      :key="index"
      class="
        bg_col_fff
        bor_rad_8
        box_sha_0_0_8_8_black_80_per
        p_16
        m_0_16 m_t_16
      "
    >
      <div class="dis_flex">
        <div class="flex_1">
          <div class="font_16 font_bold">{{ item.label0 }}</div>
          <div class="font_24 font_bold m_t_8">{{ item.value0 }}</div>
        </div>

        <div v-if="item.label1" class="m_0_16 m_t_4">
          <div class="font_14 font_bold">{{ item.label1 }}</div>
          <div class="font_18 font_bold m_t_8">{{ item.value1 }}</div>
        </div>

        <div v-if="item.label2" class="m_0_16 m_t_4">
          <div class="font_14 font_bold">{{ item.label2 }}</div>
          <div class="font_18 font_bold m_t_8">{{ item.value2 }}</div>
        </div>

        <!--  -->
      </div>

      <div class="dis_flex m_t_8">
        <div class="flex_1 dis_flex font_12">
          <div>{{ item.column0.label }}</div>
          <div class="col_66BB6A font_bold m_l_8">{{ item.column0.value }}</div>
        </div>

        <div class="flex_1 dis_flex font_12">
          <div>{{ item.column1.label }}</div>
          <div class="col_66BB6A font_bold m_l_8">{{ item.column1.value }}</div>
        </div>

        <div class="flex_1 dis_flex font_12">
          <div>{{ item.column2.label }}</div>
          <div class="col_2396F5 font_bold m_l_8">{{ item.column2.value }}</div>
        </div>

        <!--  -->
      </div>

      <!--  -->
    </div>

    <!--  -->
  </div>
</template>

<script>
const moment = window.moment;
import { columnAmount } from '@/utils/tools';

import { brokerDashboardDataUserAnalysisPost } from '@/api/index';

export default {
  components: {},
  data() {
    return {
      statisticInfo: {},

      //
    };
  },
  computed: {},
  mounted() {
    this.init();

    //
  },
  methods: {
    moment,
    columnAmount,

    // 获取 公众号统计
    async getStatisticInfo() {
      this.statisticInfo = await brokerDashboardDataUserAnalysisPost({});
    },

    // 初始化
    async init() {
      await this.getStatisticInfo();

      //
    },

    //
  },
};
</script>
